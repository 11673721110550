// @ is an alias to /src
import HeaderImg from "@/components/home/HeaderImg.vue";

export default {
	name: "home",
	components: {
		HeaderImg
	},
	data: () => {
		return {
			summary: [
				{
					title: "行业从业经验",
					text: "N年"
				},
				{
					title: "行业领域",
					text: "肿瘤、呼吸、糖尿病"
				},
				{
					title: "近期从事临床试验类型",
					text: "国内多中心"
				},
				{
					title: "近期从事临床试验分期",
					text: "III期 "
				},
				{
					title: "综合实力评估",
					start: 5
				}
			]
		};
	}
};
